import Immutable from 'immutable';
import { createSelector } from 'reselect';

const getEventsBrochure = state => {
  return state.getIn(['events', 'eventsBrochure']);
};

export const selectBrochureData = createSelector([getEventsBrochure], eventsBrochure => eventsBrochure);

export const selectIsFetching = createSelector([getEventsBrochure], eventsBrochure => eventsBrochure.get('isFetching'));

export const selectBrochureQuery = createSelector([getEventsBrochure], eventsBrochure =>
  eventsBrochure.get('query', Immutable.Map())
);

export default {
  selectBrochureData,
  selectIsFetching,
  selectBrochureQuery
};
