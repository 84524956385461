/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Document, Page, View, StyleSheet, Text, Link, Image } from '@react-pdf/renderer';
import { libraryShape } from '@bibliocommons/bc-prop-types';
import { formatTitle, convertHtmlToText, isAllDayEvent } from 'app/helpers/events/utils';

const styles = StyleSheet.create({
  page: {
    paddingTop: 24,
    paddingBottom: 24,
    paddingHorizontal: 24
  },
  pageHeaderTitle: {
    fontSize: 15,
    marginTop: 9,
    textAlign: 'left',
    fontWeight: 600
  },
  pageHeaderDate: {
    fontSize: 9,
    marginTop: 3
  },
  pageHeaderText: {
    fontSize: 9,
    marginTop: 9
  },
  pageHeaderLink: {
    fontSize: 9
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  items: {
    // flexGrow: 1,
    width: '50%',
    padding: 6
  },
  title: {
    // TODO: add fontFamily: 'Merriweather'
    fontFamily: 'Times-Roman',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 6,
    textDecoration: 'none',
    color: '#000'
  },
  text: {
    // TODO: add fontFamily: 'Merriweather'
    fontFamily: 'Times-Roman',
    fontSize: 9,
    fontWeight: 400,
    marginBottom: 6
  },
  eventDate: {
    // TODO: add fontFamily: 'Open Sans'
    fontFamily: 'Times-Roman',
    fontSize: 12,
    fontWeight: 400,
    borderBottomWidth: 0.75,
    borderBottomColor: '##ccc',
    borderBottomStyle: 'solid',
    paddingTop: 9,
    paddingBottom: 9,
    marginBottom: 9
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 6,
    bottom: 9,
    left: 18,
    right: 0,
    textAlign: 'left'
  },
  logo: {
    minHeight: 18,
    minWidth: 18,
    maxHeight: 45,
    maxWidth: 45
  }
});

const formatEventDate = (date, locale) => {
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  };
  return new Date(date).toLocaleDateString(locale, options);
};

const formatEventTime = (start, end, locale) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric'
  };
  const eventStart = new Date(start).toLocaleTimeString(locale, options).toLocaleLowerCase(locale);
  const eventEnd = new Date(end).toLocaleTimeString(locale, options).toLocaleLowerCase(locale);
  return `${eventStart}-${eventEnd}`;
};
export default function PdfFile({
  currentLibrary,
  locale,
  pdfEvents,
  branchLocations,
  nonBranchLocations,
  eventPrograms,
  eventTypes,
  eventAudiences,
  eventLanguages
}) {
  function renderDatesInfo() {
    const startDate = formatEventDate(pdfEvents[0]?.date);
    const endDate = formatEventDate(pdfEvents[pdfEvents.length - 1]?.date);
    return startDate && endDate ? `${startDate} - ${endDate}` : null;
  }

  function renderPdfDetails() {
    return (
      <View style={styles.items} key="pdf-description">
        <Image
          debug={true}
          style={styles.logo}
          src="https://chipublib-sandbox-cms.stage.bibliocommons.com/images/IL-CPL_SANDBOX_CMS/logo.png"
        />
        <Text style={styles.pageHeaderTitle}>Events at {currentLibrary.get('longName')}</Text>
        <Text style={styles.pageHeaderDate}>{renderDatesInfo()}</Text>
        <Text style={styles.pageHeaderText}>Some events require registration</Text>
        <Text style={styles.pageHeaderText}>
          Visit a library location or{' '}
          <Link style={styles.pageHeaderLink} src={`${currentLibrary.get('gaDomainName')}/events}`}>
            {`${currentLibrary.get('gaDomainName')}/events`}
          </Link>{' '}
          to register for an event.
        </Text>
      </View>
    );
  }

  function renderTime(event) {
    const start = event.getIn(['definition', 'start']);
    const end = event.getIn(['definition', 'end']);
    if (isAllDayEvent(start, end)) {
      return <Text style={styles.text}>All Day</Text>;
    }
    return <Text style={styles.text}>{formatEventTime(start, end, locale)}</Text>;
  }

  function renderLocation(event) {
    if (event.getIn(['definition', 'isVirtual'])) {
      return <Text style={styles.text}>Online Event</Text>;
    }
    const branchLocation = event.getIn(['definition', 'branchLocationId']);
    if (branchLocation) {
      return <Text style={styles.text}>{branchLocations?.get(branchLocation).get('name')}</Text>;
    }
    const nonBranchLocation = event.getIn(['definition', 'nonBranchLocationId']);
    if (nonBranchLocation) {
      return <Text style={styles.text}>{nonBranchLocations?.get(nonBranchLocation).get('name')}</Text>;
    }
    return null;
  }

  function renderTaxonomies(event) {
    let taxonomy = null;

    const programId = event.getIn(['definition', 'programId']);
    if (programId) {
      const program = eventPrograms.get(programId).get('name');
      taxonomy = program;
    }

    const typeIds = event.getIn(['definition', 'typeIds']);
    if (typeIds) {
      const types = typeIds.map(type => eventTypes.get(type).get('name')).toJS();
      const type = types.join(' - ');
      taxonomy = taxonomy ? `${taxonomy} - ${type}` : type;
    }

    const audienceIds = event.getIn(['definition', 'audienceIds']);
    if (audienceIds) {
      const audiences = audienceIds.map(audience => eventAudiences.get(audience).get('name')).toJS();
      const audience = audiences.join(' - ');
      taxonomy = taxonomy ? `${taxonomy} - ${audience}` : audience;
    }

    const languageIds = event.getIn(['definition', 'languageIds']);
    if (languageIds) {
      const languages = languageIds.map(lang => eventLanguages.get(lang).get('name')).toJS();
      const language = languages.join(' - ');
      taxonomy = taxonomy ? `${taxonomy} - ${language}` : language;
    }

    return taxonomy ? <Text style={styles.text}>{taxonomy}</Text> : null;
  }

  return (
    <Document
      title={`Events at ${currentLibrary.get('longName')}`}
      author={currentLibrary.get('longName')}
      pageLayout="twoColumnLeft"
    >
      <Page size="LETTER" style={styles.page}>
        <View style={styles.container}>
          {renderPdfDetails()}
          {pdfEvents?.map(eventData => (
            <View style={styles.items} wrap={false}>
              <Text style={styles.eventDate}>{formatEventDate(eventData.date)}</Text>
              {eventData.events.map(event => (
                <View key={`event-${event.get('id')}`} wrap={false}>
                  <Link style={styles.title} src={`${currentLibrary.get('gaDomainName')}/events/${event.get('id')}`}>
                    {formatTitle(event.getIn(['definition', 'title']).trim())}
                  </Link>
                  {renderTime(event)}
                  {renderLocation(event)}
                  <Text style={styles.text}>
                    {convertHtmlToText(event.getIn(['definition', 'description']).trim())}
                  </Text>
                  {renderTaxonomies(event)}
                </View>
              ))}
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
}

PdfFile.propTypes = {
  currentLibrary: libraryShape.isRequired,
  locale: PropTypes.string.isRequired,
  pdfEvents: PropTypes.array.isRequired,
  branchLocations: ImmutablePropTypes.map,
  nonBranchLocations: ImmutablePropTypes.map,
  eventPrograms: ImmutablePropTypes.map,
  eventTypes: ImmutablePropTypes.map,
  eventAudiences: ImmutablePropTypes.map,
  eventLanguages: ImmutablePropTypes.map
};
