/* eslint @bibliocommons/bibliocommons/dataKey:0 */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ImmutablePropTypes from 'react-immutable-proptypes';
import isEqual from 'lodash/isEqual';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import { useRouter } from '@bibliocommons/utils-routing';
import { Spinner } from '@bibliocommons/base-icons';
import { TransactionalSolidButton } from '@bibliocommons/base-buttons';
import { ModalHeader, ModalContent, ModalFooter } from '@bibliocommons/modal';
import { BROCHURE_LIMIT } from 'app/constants/EventsConstants';
import { selectCurrentLibrary } from 'app/selectors/LibrarySelector';
import { selectLocalization } from 'app/selectors/LocalizationSelector';
import { selectBrochureData, selectIsFetching, selectBrochureQuery } from 'app/selectors/events/EventsBrochureSelector';
import EventsActions from 'app/actions/EventsActions';
import { groupEventsByDate } from 'app/helpers/events/utils';
import PdfFile from './PdfFile';

import './EventsBrochureModal.scss';

export default function EventsBrochureModal({ pagination }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [client, setClient] = useState(false);
  const [isFetching, setIsFetching] = useState(useSelector(selectIsFetching));
  const currentLibrary = useSelector(selectCurrentLibrary);
  const locale = useSelector(selectLocalization).currentLanguage;
  const brochureQuery = useSelector(selectBrochureQuery);
  const brochureData = useSelector(selectBrochureData);
  const branchLocations = brochureData?.get('locations');
  const nonBranchLocations = brochureData?.get('places');
  const eventPrograms = brochureData?.get('eventPrograms');
  const eventTypes = brochureData?.get('eventTypes');
  const eventAudiences = brochureData?.get('eventAudiences');
  const eventLanguages = brochureData?.get('eventLanguages');
  const brochureDataAvailable = brochureData?.get('results').size > 0 && isEqual(brochureQuery.toJS(), router.query);

  const events = useMemo(() => {
    if (brochureDataAvailable) return groupEventsByDate(brochureData.get('events'));
    return null;
  }, [brochureData, brochureDataAvailable]);

  useEffect(() => {
    setClient(true);
  }, [client]);

  function renderLoadingState() {
    setIsFetching(true);
    return (
      <div className="creating-pdf">
        <Spinner primary />
        <span>
          <FormattedMessage defaultMessage="Creating PDF..." id="pdf.events.creating" />
        </span>
      </div>
    );
  }

  function handlePrimaryAction() {
    if (!brochureDataAvailable) {
      dispatch(EventsActions.fetchEventsForBrochure(router.query));
      setIsFetching(true);
      return renderLoadingState();
    }
    return null;
  }

  function renderModalContent() {
    if (!brochureDataAvailable && !isFetching && pagination?.get('count') > BROCHURE_LIMIT) {
      return (
        <>
          <ModalContent>
            <FormattedHTMLMessage
              defaultMessage="A PDF can only be created with 200 or fewer events."
              id="pdf.events.description"
            />
          </ModalContent>
          <ModalFooter
            primaryActionButtonText={
              <FormattedMessage defaultMessage="Create with first 200 events" id="pdf.events.create_button" />
            }
            primaryActionButtonTag={TransactionalSolidButton}
            secondaryActionButtonText={<FormattedMessage defaultMessage="Cancel" id="button_cancel" />}
            handlePrimaryAction={handlePrimaryAction}
            dataKey="events-pdf-modal"
          />
        </>
      );
    }

    if (client && brochureDataAvailable) {
      return (
        <ModalContent>
          <div className="download-pdf">
            <div className="pdf-ready-text">
              <FormattedMessage defaultMessage="Your PDF is ready for download" id="pdf.events.ready" />
            </div>
            <div className="download-button">
              <PDFDownloadLink
                document={
                  <PdfFile
                    currentLibrary={currentLibrary}
                    locale={locale}
                    pdfEvents={events}
                    branchLocations={branchLocations}
                    nonBranchLocations={nonBranchLocations}
                    eventPrograms={eventPrograms}
                    eventTypes={eventTypes}
                    eventAudiences={eventAudiences}
                    eventLanguages={eventLanguages}
                  />
                }
                fileName="brochure.pdf"
              >
                {brochureDataAvailable ? (
                  <TransactionalSolidButton>
                    <FormattedMessage defaultMessage="Download PDF" id="download_pdf" />
                  </TransactionalSolidButton>
                ) : (
                  renderLoadingState()
                )}
              </PDFDownloadLink>
            </div>
          </div>
        </ModalContent>
      );
    }

    return (
      <ModalContent>
        <div className="creating-pdf">
          <Spinner primary />
          <span>
            <FormattedMessage defaultMessage="Creating PDF..." id="pdf.events.creating" />
          </span>
        </div>
      </ModalContent>
    );
  }

  return (
    <div className="cp-events-brochure-modal">
      <ModalHeader title={<FormattedMessage defaultMessage="Create PDF" id="create_pdf" />} />
      {renderModalContent()}
    </div>
  );
}

EventsBrochureModal.propTypes = {
  pagination: ImmutablePropTypes.map
};
