import moment from 'moment';
import { stripTags, truncate } from '@bibliocommons/utils-html';

function formatTitle(title) {
  // strip out special characters (e.g. #, $) replace & with 'and'
  return title.replace(/[#$]/g, '').replace(/[&]/g, 'and');
}

function formatDescription(description, length) {
  let plainDescription = stripTags(description, '<p>').replace(/<[^>]*>/g, '');
  if (length) {
    plainDescription = truncate(plainDescription, length);
  }
  return plainDescription.replace(/[\r\n]+/gm, '\\n').replace(/[#$&]/gm, '');
}

function formatDate(date, format) {
  return moment(date)
    .utc()
    .format(format);
}

function parseDateTime(dateTimeStr) {
  const [dateStr, timeStr] = dateTimeStr.split('T');
  return {
    date: dateStr ? moment(dateTimeStr).format('LL') : null,
    time: timeStr ? moment(dateTimeStr).format('LT') : null
  };
}

/* eslint-disable no-useless-concat */
function formatAddress(location) {
  const address =
    `${location?.get('number')} ` ||
    '' + `${location?.get('street')}, ` ||
    '' + `${location?.get('city')} ` ||
    '' + `${location?.get('state')} ` ||
    '' + `${location?.get('zip')}` ||
    '';
  return address ? location?.get('name') : `${location?.get('name')}, ${address}`;
}

function isAllDayEvent(start, end) {
  const { date: startDate, time: startTime } = parseDateTime(start);
  const { date: endDate, time: endTime } = parseDateTime(end);
  return startDate === endDate && !startTime && !endTime;
}

function convertHtmlToText(description, length = 120) {
  let plainDescription = stripTags(description, '<p>').replace(/<[^>]*>/g, '');
  if (length) {
    plainDescription = truncate(plainDescription, length);
  }
  return plainDescription
    .replace(/(&nbsp;)+/gm, ' ')
    .replace(/[\r\n]+/gm, ' ')
    .replace(/[#$&]/gm, ' ');
}

function groupEventsByDate(events) {
  const eventGroups = events.reduce((groups, event) => {
    const date = event.get('indexStart').split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(event);
    return groups;
  }, {});

  const groupedEvents = Object.keys(eventGroups)
    .sort()
    .map(date => {
      return {
        date,
        events: eventGroups[date]
      };
    });
  return groupedEvents;
}

export {
  formatTitle,
  formatDescription,
  formatDate,
  formatAddress,
  isAllDayEvent,
  convertHtmlToText,
  groupEventsByDate
};
